import { useEffect, useState } from 'react';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import factorsMapper from '../../helpers/factorsMapper.helper';
import APIService from '../../services/API.service';
import InterventionCard from '../InterventionCard/InterventionCard';
import InterventionCardFull from '../InterventionCardFull/InterventionCardFull';
import CulturlabsChip from '../atoms/CulturlabsChip/CulturlabsChip';

const HEADER_TEXT = 'Intervention Catalogue';
const PAGE_SIZE = 100;

const setAllFieldsToValue = (obj, value) => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach(key => {
    newObj[key] = value;
  });
  return newObj;
};

const InterventionCataloguePage = () => {
  const [chipsState, setChipsState] = useState({});
  const [selectedIntervention, setSelectedIntervention] = useState(null);
  const [interventions, setInterventions] = useState([]);
  const [filteredInterventions, setFilteredInterventions] = useState([]);
  const [factors, setFactors] = useState([]);
  const [areAllFactorsActive, setAreAllFactorsActive] = useState(true);

  const init = () => {
    const bearer = localStorage.getItem('token');
    const headers = { Authorization: `Bearer ${bearer}` };
    let factorsWithSubfactors = [];

    APIService.call({
      method: 'get',
      url: APIService.urls.questionsFactors,
      headers,
      params: {
        populate: '*',
      },
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const newChipState = {};
        const flat = data.data.map(({ id, attributes }) => {
          newChipState[attributes.value] = true;

          return {
            id,
            attributes: {
              ...attributes,
              subfactors: attributes.subfactors.data,
            },
          };
        });

        factorsWithSubfactors = flat;
        setFactors(flat);
        setChipsState(newChipState);
      });

    APIService.call({
      method: 'get',
      url: APIService.urls.interventions,
      headers,
      params: {
        pagination: {
          pageSize: PAGE_SIZE,
        },
      },
    })
      .catch(emptyArrowFunc)
      .then(({ data }) => {
        const flat = data.data.map(({ id, attributes }) => ({
          id,
          ...attributes,
          factors: {
            ...attributes.factors,
            data: attributes.factors.data.map(factor => {
              const currentFactor = factorsWithSubfactors.find(({ id }) => id === factor.id);
              return {
                ...factor,
                attributes: {
                  ...factor.attributes,
                  subfactors: currentFactor.attributes.subfactors,
                },
              };
            }),
          },
        }));

        setInterventions(flat);
      });
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    setAreAllFactorsActive(Object.values(chipsState).every(Boolean));
  }, [chipsState]);

  useEffect(() => {
    filterInterventions();
  }, [chipsState, interventions]);

  const filterInterventions = () => setFilteredInterventions(interventions.filter(filterByCurrentSelection));

  const proceedChipSelection = value =>
    setChipsState(prevState =>
      areAllFactorsActive
        ? {
            ...setAllFieldsToValue(prevState, false),
            [value]: true,
          }
        : {
            ...prevState,
            [value]: !prevState[value],
          },
    );

  const filterByCurrentSelection = ({ factors: { data } }) =>
    Object.entries(chipsState).some(([factorValue, isEnabled]) =>
      data.some(({ attributes: { value } }) => isEnabled && factorValue === value),
    );

  const renderTags = () => {
    const selectedFactors = selectedIntervention ? selectedIntervention.factors?.data : factors;
    const factorsToRender = selectedFactors.map(({ id, attributes: { label, value } }) => (
      <CulturlabsChip
        key={id}
        label={label}
        className={'h-[36px] rounded-lg font-bold'}
        disabled={selectedIntervention}
        selected={!areAllFactorsActive ? chipsState[value] : false}
        onClickCallback={selectedIntervention ? undefined : () => proceedChipSelection(value)}
        mapper={selectedIntervention ? undefined : () => factorsMapper(selectedFactors, value)}
      />
    ));
    const subfactorsToRender = selectedFactors.map(factor =>
      (!selectedIntervention && !chipsState[factor.attributes.value]) || areAllFactorsActive
        ? null
        : factor.attributes.subfactors.map(({ id, attributes: { label } }) => (
            <CulturlabsChip key={id} className={'rounded-lg'} disabled label={label} />
          )),
    );

    return (
      <>
        <div className="flex min-h-[36px] gap-[8px]">
          {factorsToRender}
          {!selectedIntervention && (
            <CulturlabsChip
              label={'SELECT ALL'}
              className={'h-[36px] rounded-lg font-bold'}
              disabled={selectedIntervention}
              selected={areAllFactorsActive}
              onClickCallback={() => setChipsState(prevState => setAllFieldsToValue(prevState, true))}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-[8px] min-h-[24px]">{subfactorsToRender}</div>
      </>
    );
  };

  const renderBody = () =>
    selectedIntervention ? (
      <InterventionCardFull intervention={selectedIntervention} close={() => setSelectedIntervention(null)} />
    ) : (
      filteredInterventions.map(intervention => (
        <InterventionCard
          key={intervention.id}
          intervention={intervention}
          click={() => setSelectedIntervention(intervention)}
        />
      ))
    );

  return (
    <section className="w-full bg-lightgray">
      <div className="flex flex-col w-full  h-[calc(100vh-40px)] p-[48px] overflow-y-auto">
        <h1 className="flex flex-row items-center text-main-black text-xxl font-lato font-bold mb-[32px] capitalize">
          {HEADER_TEXT}
        </h1>
        <div className="flex flex-row mb-[28px]">
          <span className="font-lato text-semi-xl text-main-black font-[700] mr-[16px]">Tags</span>
          <div className="flex flex-col gap-[8px]">{renderTags()}</div>
        </div>
        <div className="flex flex-row flex-wrap justify-between gap-[24px] justify-items-start">{renderBody()}</div>
      </div>
    </section>
  );
};

export default InterventionCataloguePage;
