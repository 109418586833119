import classNames from 'classnames';
import React, { useState } from 'react';

import { ReactComponent as CloseSVG } from '../../assets/close.svg';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';

const PasswordChangeModal = ({ onApply = emptyArrowFunc, closeModal = emptyArrowFunc }) => {
  const [controlsState, setControlsState] = useState({
    password: '',
    newPassword: '',
    repeatPassword: '',
  });
  const [error, setError] = useState('');

  const validatePasswords = () => {
    if (controlsState.password.length < 6) {
      return 'The old password must be at least 6 characters.';
    } else if (controlsState.newPassword.length < 6 || controlsState.repeatPassword.length < 6) {
      return 'The new password must be at least 6 characters.';
    } else if (controlsState.newPassword !== controlsState.repeatPassword) {
      return 'Passwords do not match.';
    } else if (controlsState.password === controlsState.newPassword) {
      return 'The new password should not match the old one.';
    }
    return '';
  };

  const handleChange = (formControlName, originalEvent) => {
    const value = unwrapValueFromInputChangeEvent(originalEvent) || '';
    setControlsState(prevState => ({
      ...prevState,
      [formControlName]: value,
    }));
  };

  const handleApply = () => {
    const validationMessage = validatePasswords();
    if (validationMessage) {
      setError(validationMessage);
      return;
    }
    setError('');
    onApply({ password: controlsState['password'], newPassword: controlsState['newPassword'] });
  };

  const validationMessage = validatePasswords();
  const isApplyDisabled = !!validationMessage;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="absolute inset-0 bg-black opacity-50" onClick={closeModal} />
      <div className="absolute flex flex-col w-[400px] h-[500px] rounded-2xl p-8 bg-white shadow-lg">
        <header className="flex justify-between items-center mb-8">
          <h2 className="text-xl font-bold">Update Password</h2>
          <CloseSVG className="fill-current text-black w-6 h-6 cursor-pointer" onClick={closeModal} />
        </header>
        <div className="flex flex-col items-center">
          <CulturlabsInput
            label="Existing Password"
            inputType="password"
            className="mb-4 w-full"
            handleChange={e => handleChange('password', e)}
          />
          <CulturlabsInput
            label="New Password"
            inputType="password"
            className="mb-4 w-full"
            handleChange={e => handleChange('newPassword', e)}
          />
          <CulturlabsInput
            label="Repeat New Password"
            inputType="password"
            className="mb-4 w-full"
            handleChange={e => handleChange('repeatPassword', e)}
          />
          {isApplyDisabled && <p className="text-red-500 text-sm mb-2">{validationMessage}</p>}
        </div>
        <CulturlabsButton
          type="button"
          className={classNames(
            'mt-auto',
            { 'bg-blue-300': isApplyDisabled, 'bg-blue-500 hover:bg-blue-600': !isApplyDisabled },
            'text-white w-full py-2 rounded-md',
          )}
          onClick={handleApply}
          disabled={isApplyDisabled}
        >
          Apply
        </CulturlabsButton>
      </div>
    </div>
  );
};

export default PasswordChangeModal;
