import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import handleInputChange from '../../helpers/handleInputChange.helper';
import onSelectChange from '../../helpers/onSelectChange.helper';
import { idMapper, labelRelationMapper } from '../../helpers/selectMappers.helper';
import APIService from '../../services/API.service';
import PasswordChangeModal from '../PasswordChangeModal/PasswordChangeModal';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsSelect from '../atoms/CulturlabsSelect/CulturlabsSelect';

const EditEmployeeProfilePage = ({ type }) => {
  const [changesToUser, setChangesToUser] = useState({});
  const [organizations, setOrganizations] = useState([]);
  const [teams, setTeams] = useState([]);
  const [currentDepartments, setCurrentDepartments] = useState([]);
  const [currentTeams, setCurrentTeams] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [initDepartment, setInitDepartment] = useState();
  const isEditMe = type === 'myself';
  const token = localStorage.getItem('token');
  const [editedUser, setEditedUser] = useState(null);
  const meString = localStorage.getItem('me');
  const me = JSON.parse(meString);
  const { id: userToEditId } = useParams();
  const [isShowPasswordModal, setIsShowPasswordModal] = useState(false);

  const getUserData = () => {
    const url = `${APIService.urls.users}/${userToEditId || me.id}`;
    APIService.call({
      method: 'get',
      url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .catch(emptyArrowFunc)
      .then(response => {
        const data = response?.data?.data || response?.data;
        setEditedUser(data);
      });
  };

  const callOrgItems = (url, setter) =>
    APIService.call({
      method: 'get',
      url,
      headers: { Authorization: `Bearer ${token}` },
    })
      .catch(emptyArrowFunc)
      .then(response => {
        setter(response?.data?.data);
        return response?.data?.data;
      });

  const callOrganizations = () => callOrgItems(APIService.urls.organizations, setOrganizations);

  const callDepartments = orgId =>
    callOrgItems(`${APIService.urls.departments}?byOrganization=${orgId}`, setCurrentDepartments);

  const init = async () => {
    const usersOrganization = await callOrganizations();
    setOrganizations([usersOrganization]);
    setSelectedOrg(usersOrganization);

    await onOrganizationSelected(changesToUser, usersOrganization?.id);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    getUserData();
  }, []);

  const onOrganizationSelected = async (formUpdate, selectedOrganizationId) => {
    formUpdate['department'] = null;
    formUpdate['team'] = null;

    setCurrentTeams([]);

    const depts = await callDepartments(selectedOrganizationId);
    setCurrentDepartments(depts);
  };
  const findDepartmentByTeamId = (departments, teamId) => {
    return departments.find(department => department.teams.some(team => team?.id === teamId));
  };

  useEffect(() => {
    if (editedUser?.team && currentDepartments?.length) {
      const matchingDepartment = findDepartmentByTeamId(currentDepartments, editedUser?.team?.id);
      console.log(matchingDepartment, 'match');
      if (matchingDepartment) {
        setInitDepartment(matchingDepartment);
        const teamsOfDepartment = teams.filter(currentTeam => currentTeam.departmentId == matchingDepartment?.id);
        setCurrentTeams(teamsOfDepartment);
      }
    }
  }, [editedUser, currentDepartments]);
  const onDepartmentSelected = (formUpdate, selectedDepartment) => {
    formUpdate['team'] = null;
    const teamsOfDepartment = teams.filter(currentTeam => currentTeam.departmentId == selectedDepartment);
    setCurrentTeams(teamsOfDepartment);
  };

  const onSubmit = async (event, data) => {
    event?.preventDefault();
    const url = `${APIService.urls.users}/${userToEditId || me.id}`;
    try {
      const response = await APIService.call({
        method: 'put',
        url,
        data,
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        setIsShowPasswordModal(false);
      }
      return response.data;
    } catch (error) {
      console.error('An error occurred:', error);
      throw error;
    }
  };

  const togglePasswordModal = () => setIsShowPasswordModal(!isShowPasswordModal);

  const roles = ['Employee', 'Admin'];

  return (
    <div className="w-full h-[calc(100vh-90px)] p-[48px] overflow-y-auto">
      <div className="w-[336px] flex flex-col justify-start ">
        <h1 className="text-main-black text-xxl mb-[16px]">{getPageTitle(isEditMe)}</h1>
        <CulturlabsInput
          label="full name"
          className="mb-[16px]"
          initValue={editedUser?.name}
          handleChange={changeEvent => handleInputChange('fullname', changeEvent, setChangesToUser, changesToUser)}
        />
        <CulturlabsInput
          label="email"
          className="mb-[16px]"
          disabled={!isEditMe}
          initValue={editedUser?.email}
          handleChange={changeEvent => handleInputChange('email', changeEvent, setChangesToUser, changesToUser)}
        />
        <CulturlabsSelect
          className="mb-[16px]"
          label="Department"
          initValue={initDepartment?.id}
          listItems={currentDepartments}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          onChange={(type, event) => onSelectChange(type, event, setChangesToUser, changesToUser, onDepartmentSelected)}
        ></CulturlabsSelect>
        <CulturlabsSelect
          className="mb-[16px]"
          label="Team"
          initValue={editedUser?.team?.id}
          listItems={currentTeams}
          idMapper={idMapper}
          labelMapper={labelRelationMapper}
          onChange={(type, event) => onSelectChange(type, event, setChangesToUser, changesToUser)}
        ></CulturlabsSelect>
        {me.isCulturlabsAdmin && (
          <CulturlabsSelect
            className="mb-[16px] w-full"
            label="Role"
            listItems={roles}
            initValue={editedUser?.isCulturlabsAdmin ? 'Admin' : 'Employee'}
            idMapper={item => item}
            labelMapper={item => item}
            renderOption={item => (
              <option key={item} value={item}>
                {item}
              </option>
            )}
            onChange={(label, changeEvent) => handleInputChange('role', changeEvent, setChangesToUser, changesToUser)}
          ></CulturlabsSelect>
        )}
        {isEditMe && (
          <CulturlabsButton type="button" className="w-full" onClick={togglePasswordModal}>
            Update Password
          </CulturlabsButton>
        )}
      </div>

      <CulturlabsButton
        type="submit"
        className="mt-auto ml-auto w-[226px]"
        onClick={event => onSubmit(event, changesToUser)}
      >
        Save Changes
      </CulturlabsButton>
      {isShowPasswordModal && (
        <PasswordChangeModal
          onApply={payload => onSubmit(null, payload)}
          closeModal={() => setIsShowPasswordModal(false)}
        />
      )}
    </div>
  );
};

export default EditEmployeeProfilePage;

const getPageTitle = isEditMe => (isEditMe ? 'Settings' : 'Edit Employee Profile');
